import React, {Component} from 'react'
import {get, find, filter, includes, map, has, difference, omit, isEmpty, some, toUpper, sortBy, isNil,
  flowRight as compose, values, flatten, groupBy, isObject, split, reverse, toLower, lowerCase, upperCase,
  reject, last} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Avatar from '@mui/material/Avatar'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import {ADD_ACCOUNT_MUTATION} from '../../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import Images from '../../Common/Images'
import {FullScreenDialog, AlertDialog} from '../../Common/Dialog'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import PageSubTitle from '../../Common/PageSubTitle'
import classNames from 'classnames'
import {accountTypes, accountSubtypes, currencies, whiteLabels, kycStatuses, countries, clientTypes} from '@bdswiss/common-enums'
import {isMobile} from '../../../common/utils/browser'
import AppContext from '../../Common/contexts/AppContext'
import ChangePlan from '../Forex/Plan/ChangePlan'
import {checkPassword, findCompany} from '../../../common/utils'
import {getAccountLabel, getAccountSubtype, getAccountTypeForStats, getAllowedAccounts, isCentAccount, isMt5ForexAccount, isWalletAccount, isCentMt5Account, getAccountCurrencies} from '../../../common/utils/accounts'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import config from '../../../config'
import {validateLength} from '../../../common/utils/validations'
import {validCountries, isWhiteLabel, getMissingDocs, isMenaCountry} from '../../../common/utils/general'
import FormControlLabel from '@mui/material/FormControlLabel'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import Help from '@mui/icons-material/HelpOutlineOutlined'
import Popper from '@mui/material/Popper'
import Fab from '@mui/material/Fab'
import {accountTypeStats, ibAppendix8, leverageTypeCards, leverageTypeTooltip, localesReverseTextAccountTypes} from '../../../common/utils/uioptions'
import CheckIcon from '@mui/icons-material/Check'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import UiNotification from '../../Common/UiNotification'
import CloseIcon from '@mui/icons-material/Close'
import {Password} from '../../Common/Password'
import {Link, withRouter, Redirect} from 'react-router-dom'
import {getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../common/utils/firebaseEvents'
import NotificationBar from '../../Common/NotificationBar'

const styles = theme => ({
  cardRoot: {
    padding: 20,
    margin: '20px 0px',
    cursor: 'pointer',
    position: 'relative',
  },
  disabledCard: {
    cursor: 'default !important',
    backgroundColor: theme.palette.extralightgrey.color
  },
  formControl:{
    display: 'block',
    margin: '20px 0px'
  },
  pointer:{
    cursor: 'pointer'
  },
  passwordTitle:{
    paddingBottom: '30px'
  },
  error:{
    color: theme.palette.error.main,
  },
  button:{
    marginTop: '40px',
    padding: '15px 30px'
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
  },
  label:{
    padding: '6px 0px'
  },
  badge: {
    padding: '9px',
    margin: '0px 0px 0px auto'
  },
  currIcon:{
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: '18px'
  },
  errorMessage:{
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  successDiv:{
    backgroundColor: theme.palette.lightgreen.color,
    padding: '15px 10px',
    marginBottom: 40
  },
  successMessage:{
    color: theme.palette.green.color,
  },
  forexMt4: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  forexMt5: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  affiliate: {
    color: theme.palette.mode === 'dark' ? '#A967FD' : theme.palette.purple.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.lightpurple.color,
  },
  ib: {
    color: theme.palette.mode === 'dark' ? '#A967FD' : theme.palette.purple.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.lightpurple.color,
  },
  badgeDiv:{
    paddingTop: 4
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  basic: {
    color: `${get(theme.palette, 'mainDark.color') || theme.palette.primary.main}`,
    backgroundColor:  `${get(theme.palette, 'main.color') || theme.palette.lightblue.color}`,
  },
  standard: {
    color: `${get(theme.palette, 'mainDark.color') || theme.palette.primary.main}`,
    backgroundColor:  `${get(theme.palette, 'main.color') || theme.palette.lightblue.color}`,
  },
  raw: {
    color: theme.palette.mode === 'dark' ? '#E55CE5' : theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  prime: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  black: {
    color: theme.palette.mode === 'dark' ? '#E55CE5' : theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  pro: {
    color: theme.palette.purple.color,
    backgroundColor:  theme.palette.lightpurple.color,
  },
  invest_plus: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  pammForex: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  premium: {
    color: theme.palette.mode === 'dark' ? '#E55CE5' : theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  stepper: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonCompare:{
    fontSize: 16,
    float: 'right',
    paddingRight: 0,
    '&:hover':{
      backgroundColor: 'transparent'
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('md')]: {
      float: 'none',
      width: '100%',
      padding: 0,
    },
  },
  labelButton: {
    alignItems: 'initial',
    justifyContent: 'initial',
  },
  dialog: {
    width: '100%'
  },
  helpIcon: {
    color: theme.palette.mode === 'dark' ? theme.palette.freshGrey.color : theme.palette.lightgrey.color,
    marginBottom: '-6px',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 10,
    padding: 2
  },
  popper: {
    zIndex: 9999,
    backgroundColor: theme.palette.lightgreyBackgroundSolid.color,
    padding: '10px 20px',
    boxShadow: theme.boxShadowStyle,
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    },
  },
  subtitlePopper: {
    paddingTop: 10
  },
  checkIcon: {
    color: theme.palette.green.color,
    marginBottom: -7,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 10,
    width: 16
  },
  availableAccounts:{
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: 10,
    top: '10px',
    color: theme.palette.primary.main
  },
  buttonPopper: {
    background: 'none',
    position: 'absolute',
    top: '0px',
    [theme.direction === 'rtl' ? 'left' : 'right']: 0,
    boxShadow: 'none',
    color: theme.palette.black.color,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    }
  },
  cent: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  centMt5: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  zero: {
    color: theme.palette.mode === 'dark' ? '#E55CE5': theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  leverageTypeNotification: {
    padding: 20,
  },
  bold: {
    fontWeight: '400',
  },
  leverageTooltip: {
    marginBottom: 20,
    marginTop: 20,
  },
  copy_vip: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_zero: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_cent: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_classic: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_vip_sp: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_zero_sp: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_cent_sp: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  copy_classic_sp: {
    color: theme.palette.yellow.color,
    backgroundColor:  theme.palette.lightyellow.color,
  },
})

export class AddAccount extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const viewer = get(props, 'viewer', {})
    const affCountries = get(viewer, 'affiliateCountries[0].countriesPromoted') || []
    const accountType = get(props, 'location.state.accountType') || get(props, 'location.state.state.accountType')
    const currency = get(props, 'location.state.currency') || get(props, 'location.state.state.currency')
    this.state = {
      accountType: accountType || '',
      currency: currency || '',
      leverageType:'',
      password: '',
      confirmationPassword: '',
      showCurrentPassword: false,
      showConfirmationPassword: false,
      messageErrorPassword:'',
      messageErrorConfirmPassword:'',
      loading: false,
      status: '',
      step: accountType ? ((currency) ? 2 : 1) : 0,
      token: '',
      selectAllAffCountries:false,
      formPartnership: {
        skypeId: get(viewer, 'skypeId') || '',
        website: get(viewer, 'website') || '',
        affiliateCountries: map(affCountries, (c) => toUpper(c)),
        isCorporate: '',
        acceptTerms: '',
        showPasswordMt5Warning: '',
      },
      errorsPartnership: {},
      websiteLength: false,
      companyObject: findCompany(get(config, 'forcedCompany')),
      openPopper: {},
      showPartnerMessage: false
    }
  }

  componentWillUnmount () {
    const {accountType, accountSubtype, currency, leverageType} = this.state
    const params = {
      platform: accountType && accountTypes[accountType].label,
      type: accountSubtype,
      currency: currency,
      leverageType: leverageType,
      userId: get(this.props, 'viewer.id')
    }
    logEventCustomParams('newTradingAccountAborted', params)
  }

  handleChangePassword(prop,event) {
    this.setState({[prop]: event.target.value, messageErrorPassword: '', messageErrorConfirmPassword: '', showPasswordMt5Warning: ''},this.checkPassword)
  }

  checkPassword() {
    const {t} = this.props
    const {password, confirmationPassword} = this.state
    if (confirmationPassword && password !== confirmationPassword)
      this.setState({messageErrorConfirmPassword: t(messages.passwordsNotSame.i18nKey, messages.passwordsNotSame.defaults)})
    else
      this.setState({messageErrorConfirmPassword: ''})
  }

  handleChangeForm(prop, value) {
    if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && !this.state.selectAllAffCountries)
      this.setState(state => ({
        formPartnership: {...state.formPartnership,
          affiliateCountries: map(validCountries(true, true, true ,true), country => country.value)},
        errorsPartnership:{...state.errorsPartnership, 'affiliateCountries': !value,},
        selectAllAffCountries:true
      }))
    else if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && this.state.selectAllAffCountries)
      this.setState(state => ({
        formPartnership: {...state.formPartnership, affiliateCountries: []},
        errorsPartnership:{...state.errorsPartnership, 'affiliateCountries': !value,},
        selectAllAffCountries:false
      }))
    else
      this.setState(state => ({
        formPartnership: {...state.formPartnership,[prop]: value},
        errorsPartnership: {...state.errorsPartnership,[prop]: !value, websiteLength: false}
      }))
  }

  checkForm(fields) {
    const {registrationFields} = config
    const exceptions = ['currency', 'leverageType']
    const validateFields = difference(fields, registrationFields['default'])
    const {formPartnership} = this.state
    const errorsPartnership = {}
    const partnership = formPartnership
    for (const field in formPartnership) {
      if (field === 'acceptTerms') {
        errorsPartnership[field] = isEmpty(formPartnership[field])
      }
      else if (includes(validateFields, field)) {
        if (field === 'website') {
          errorsPartnership[field] = isEmpty(formPartnership[field])
          errorsPartnership['websiteLength'] = !validateLength(formPartnership[field], 150)
        }
        else {
          errorsPartnership[field] = isEmpty(formPartnership[field])
        }
      } else {
        partnership[field] = ''
      }
    }

    if (some(omit(errorsPartnership,exceptions))) {
      this.setState({errorsPartnership})
      return
    }
    this.handleSubmit(partnership)
  }

  handleClickShowPassword(prop) {
    this.setState(state => ({[prop]: !state[prop]}))
  }

  handleKeyDown(prop,event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      document.getElementById(prop).focus()
    }
  }

  handleSubmit = (formPartnership) => {
    const {accountType, currency, leverageType, password, messageErrorConfirmPassword, confirmationPassword,
      token} = this.state
    let {accountSubtype} = this.state
    const onlyPartnerAccount = isWalletAccount(accountType)
    const {t, viewer, location} = this.props
    const missingPofOrPoi = values(getMissingDocs(viewer)).some(d => d === true)

    if (checkPassword(confirmationPassword) && (isMt5ForexAccount(get(accountTypes[accountType], 'baseProduct')) || isCentMt5Account(get(accountTypes[accountType], 'baseProduct')))) {
      this.setState({showPasswordMt5Warning: true})
      return
    }
    if ((password && confirmationPassword && !messageErrorConfirmPassword) || (get(accountTypes[accountType], 'walletProduct', ''))) {
      this.setState({status: '', loading: true})
      if (get(accountTypes[accountType],'supportedSubtypes') && accountTypes[accountType].supportedSubtypes.length === 1) {
        accountSubtype =  get(find(accountSubtypes, (t) => t.value===accountTypes[accountType].supportedSubtypes[0]), 'key') //MT5 add subtype for max allowed check
      }
      const payload = {password, currency, leverageType, accountType, accountSubtype,
        args: JSON.stringify({tokenId: token, countriesPromoted: formPartnership['affiliateCountries'],
          website: formPartnership.website, skypeId: formPartnership.skypeId,
          isCorporate: formPartnership.isCorporate, leverageType: leverageType})
      }
      this.props.addAccountMutation({variables: payload}).then(({data}) => {
        if (get(data, 'account')) {
          if (onlyPartnerAccount && missingPofOrPoi) {
            this.setState({showPartnerMessage: true})
          } else {
            const params = {
              type: accountSubtype,
              platform: accountType,
              currency: currency,
              leverageType: leverageType,
              userId: get(viewer, 'id'),
              source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')}))
            }
            logEventCustomParams('newTradingAccountAdded', params)
            this.setState({loading: false,status: 'success',submitMessageError: ''})
            window.location.href = '/accounts?accountCreated=true'
          }
        }
        else {
          this.setState({loading: false,status: 'failure',
            submitMessageError: t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults),
          })
        }
      })
        .catch((err) => {
          if (err.networkError) {
            logEventCustomParams('newTradingAccountError', {
              reason: 'networkError',
              userId: get(viewer, 'id')
            })
            this.setState({loading: false,status: 'failure',
              submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
            })
          } else {
            const error = get( err, 'graphQLErrors[0].message') || err.message
            logEventCustomParams('newTradingAccountError', {reason: error, userId: get(viewer, 'id')})
            this.setState({loading: false,status: 'failure',
              submitMessageError: error,
            })
          }

        })
    }
    if (!confirmationPassword)
      this.setState({messageErrorConfirmPassword: t(messages.requiredField.i18nKey, messages.requiredField.defaults)})
    if (!password)
      this.setState({messageErrorPassword: t(messages.requiredField.i18nKey, messages.requiredField.defaults)})
  }

  chooseAccount(accountType, accountSubtype) {
    this.setState({accountType:accountType})
    if (isCentAccount({__typename: accountType}) || includes(accountSubtype, 'cent')) {
      this.setState({currency: currencies.CUD.value})
    }
    (!accountSubtype || accountSubtype==='demo')
      ? this.setState({accountSubtype:undefined},this.increaseStep)
      : this.setState({accountSubtype:accountSubtype},this.increaseStep)
  }

  chooseCurrency(props) {
    this.setState({currency:props},this.increaseStep)
  }

  decreaseStep() {
    const {history} = this.props
    const {step,status} = this.state
    this.setState({openPopper: {}})
    if (step === 1) {
      this.setState({accountType:'', accountSubtype: undefined})
    }
    (step > 0 && !status) ? this.setState({step:step-1}): history.push('/')
  }

  increaseStep() {
    this.setState(state => ({step:++state.step}))
  }

  renderForm() {
    const {classes} = this.props
    const {showCurrentPassword, password, showConfirmationPassword, confirmationPassword, messageErrorConfirmPassword,
      messageErrorPassword, loading, status, submitMessageError, showPasswordMt5Warning, accountType, currency} = this.state

    return (
      <Grid container>
        <Grid item xs={12} sm={10}>
          <PageSubTitle>
            <Trans {...messages.passwordVerification} />
            <Typography variant='caption'> <Trans {...messages.addAccountPassDesc} /></Typography>
          </PageSubTitle>
          <Password
            classes={classes}
            showPassword={showCurrentPassword}
            onChange={(e) => this.handleChangePassword('password',e)}
            onKeyDown={(e)=>this.handleKeyDown('confirmationPassword',e)}
            error={!!messageErrorPassword}
            onClickShow={() => this.handleClickShowPassword('showCurrentPassword')}
            fullWidth
            errorText={messageErrorPassword}
            value={password}
          />
          <Password
            id={'confirmationPassword'}
            classes={classes}
            showPassword={showConfirmationPassword}
            onChange={(e)=>this.handleChangePassword('confirmationPassword',e)}
            onKeyDown={(e)=>this.handleKeyDown('loadingButton',e)}
            error={!!messageErrorConfirmPassword}
            onClickShow={() => this.handleClickShowPassword('showConfirmationPassword')}
            fullWidth
            errorText={messageErrorConfirmPassword}
            label={'passwordConfirmation'}
            value={confirmationPassword}
          />
          <LoadingButton
            id='loadingButton'
            onClick={this.handleSubmit}
            fullWidth={isMobile()}
            disabled={loading || showPasswordMt5Warning || false}
            hideProgressBar={showPasswordMt5Warning}
            status={status}
          ><Trans {...messages.addAccount} />
          </LoadingButton>
          {status==='failure' &&
            <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
          {showPasswordMt5Warning &&
            <FormHelperText className={classes.subtitlePopper}><Trans {...messages.passwordStepMt5} components={[<Link
              to={{
                pathname: '/settings/change-password',
                state: {accountType, currency}
              }}
              className={classes.link}> </Link>]} />
            </FormHelperText>}
        </Grid>
      </Grid>
    )
  }

  chooseLeverageType(props) {
    this.setState({leverageType: props},this.increaseStep)
  }

  renderLeverageType() {
    const {classes} = this.props
    return map(leverageTypeCards, (a) => <Card classes={{root: classes.cardRoot}} key={a.key} onClick={()=> this.chooseLeverageType(a.key)}>
      <Grid container>
        <Grid item>
          <Typography variant='subtitle1' className={classes.label}>
            <Trans {...(a.title) ? a.title : ''} />
          </Typography>
        </Grid>
      </Grid>
    </Card>)
  }

  renderDetailsForm() {
    const {classes, t, viewer} = this.props
    const {registrationFields, weblinks: {termsAndConditions, legalDocuments, privacyPolicy, affiliateTermsAndConditions, affiliateGuidelines, affiliateCodeConduct, ibTermsAndConditions, ibGuidelines,
      ibCodeConduct, ibMasterTermsAndConditions}, translatedPartnerGuidelines, documents} = config
    const {accountType, formPartnership: {skypeId, website, affiliateCountries, isCorporate}, selectAllAffCountries, submitMessageError, loading, status, companyObject, acceptTerms} = this.state
    const accountTypeObj = accountTypes[accountType]
    const fields = (has(registrationFields, accountTypeObj.category) || has(registrationFields, accountTypeObj.subCategory)) &&
      ((registrationFields[accountTypeObj.category]) ? registrationFields[accountTypeObj.category] : registrationFields[accountTypeObj.subCategory])
    const {locale} = this.context
    const isAffiliate = includes(accountTypeObj.category,'affiliate')
    const country = toLower(get(viewer, 'address.country', ''))
    const checkAppendixLink = includes(ibAppendix8, lowerCase(country)) ?
      (get(documents, `ibAppendixMena[${locale}]`) || get(documents, 'ibAppendixMena.default') || get(documents, 'ibAppendixMena')) : (get(documents, `ibAppendix[${locale}]`) || get(documents, 'ibAppendix.default') || get(documents, 'ibAppendix'))
    const checkAppendixNumber = includes(ibAppendix8, lowerCase(country)) ? get(documents, 'appendixNumberMena') : get(documents, 'appendixNumber')
    const affiliateTermsAndCond = affiliateTermsAndConditions?.[locale] || affiliateTermsAndConditions?.default || affiliateTermsAndConditions
    const ibTermsAndCond = ibTermsAndConditions?.[locale] || ibTermsAndConditions?.default || ibTermsAndConditions
    const affiliateCodeCond =  affiliateCodeConduct?.[locale] || affiliateCodeConduct?.default || affiliateCodeConduct
    const ibCodeCond =  ibCodeConduct?.[locale] || ibCodeConduct?.default || ibCodeConduct
    const affiliateGuid =  affiliateGuidelines?.[locale] || affiliateGuidelines?.default || affiliateGuidelines
    const ibGuid =  ibGuidelines?.[locale] || ibGuidelines?.default || ibGuidelines
    const ibMasterTermsAndCond =  ibMasterTermsAndConditions?.[locale] || ibMasterTermsAndConditions?.default || ibMasterTermsAndConditions

    return (
      <Grid container>
        <PageSubTitle>
          <Trans {...messages.moreDetails} />
          <Typography variant='caption'> <Trans {...messages.addAccountDetailsDesc} /></Typography>
        </PageSubTitle>
        {includes(fields,'skypeId') && <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            required
            id="skypeId"
            label={t(messages.skypeId.i18nKey, messages.skypeId.defaults)}
            onChange={(e) => this.handleChangeForm('skypeId', e.target.value)}
            error={this.state.errorsPartnership.skypeId}
            value={skypeId} />
        </Grid>}
        {includes(fields,'website') && <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            required
            id="website"
            label={t(messages.website.i18nKey, messages.website.defaults)}
            onChange={(e) => this.handleChangeForm('website', e.target.value)}
            error={this.state.errorsPartnership.website || this.state.errorsPartnership.websiteLength}
            value={website} />
          {this.state.errorsPartnership.websiteLength && <FormHelperText className={classes.error}>
            <Trans {...messages.maxLengthError} values={{length: 150}}/>
          </FormHelperText>}
        </Grid>}
        {includes(fields,'affiliateCountries') && <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="affiliateCountries" error={this.state.errorsPartnership.affiliateCountries} required>
              {t(messages.affiliateCountries.i18nKey, messages.affiliateCountries.defaults)}
            </InputLabel>
            <Select
              variant="standard"
              multiple
              required
              id="affiliateCountries"
              placeholder="affiliateCountries"
              name="affiliateCountries"
              onChange={(e) => this.handleChangeForm('affiliateCountries', e.target.value)}
              error={this.state.errorsPartnership.affiliateCountries}
              value={affiliateCountries}
              renderValue={selected => selected.join(', ')}
              classes={{selectMenu:classes.selectMenu}}>
              <MenuItem value={'selectAll'}>
                <Checkbox checked={selectAllAffCountries} />
                <ListItemText primary={t(messages.selectAll.i18nKey, messages.selectAll.defaults)} />
              </MenuItem>
              {map(validCountries(true, true, true ,true), (option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={affiliateCountries.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>}
        {includes(fields,'isCorporate') && <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel error={this.state.errorsPartnership.isCorporate}>
              <Trans {...messages.company} /> / <Trans {...messages.individual} />
            </InputLabel>
            <Select
              variant="standard"
              value={isCorporate}
              onChange={(e) => this.handleChangeForm('isCorporate', e.target.value)}
              error={this.state.errorsPartnership.isCorporate}
              required>
              <MenuItem key={'company'} value={'true'}><Trans {...messages.company} /></MenuItem>
              <MenuItem key={'individual'} value={'false'}><Trans {...messages.individual} /></MenuItem>
            </Select>
          </FormControl>
        </Grid>}
        <Grid item xs={12} mt={isMobile() ? 2 : 3}>
          <FormControl variant="standard">
            <FormControlLabel
              classes={{label:classes.checkboxLabel}}
              control={
                <Checkbox
                  checked={this.state.acceptTerms}
                  onChange={(e) => this.handleChangeForm('acceptTerms', e.target.checked? 'accept' : '')}
                  color="primary"
                  value={acceptTerms}
                  className={this.state.errorsPartnership.acceptTerms ? classes.error : ''}
                />
              }
              label={isAffiliate ?
                <Trans {...messages.signUpAffiliateCheckbox} components={[
                  <a href={termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                  <a href={legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                  <a href={privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                  <a href={affiliateTermsAndCond} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                  <a href={affiliateCodeCond} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                  <a href={includes(translatedPartnerGuidelines, locale) ? affiliateGuid.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : affiliateGuid}
                    target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                ]} />
                :
                <Trans {...messages.signUpIBCheckbox} values={{company:companyObject.brandLabel, appendixNumber: checkAppendixNumber}} components={[
                  <a href={termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                  <a href={legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                  <a href={privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                  <a href={ibCodeCond} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                  <a href={includes(translatedPartnerGuidelines, locale) ? ibGuid.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : ibGuid}
                    target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                  <a href={ibTermsAndCond} target='_blank' rel='noreferrer noopener' className={classes.link}>ibTerms</a>,
                  <a href={ibMasterTermsAndCond} target='_blank' rel='noreferrer noopener' className={classes.link}>ibMasterTerms</a>,
                  <a href={checkAppendixLink} target='_blank' rel='noreferrer noopener' className={classes.link}>appendix</a>,
                ]} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            id='loadingButtonPartnership'
            onClick={() => this.checkForm(fields)}
            fullWidth={isMobile()}
            disabled={loading}
            status={status}
          ><Trans {...messages.addAccount} />
          </LoadingButton>
          {status==='failure' &&
          <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
        </Grid>
      </Grid>
    )
  }


  clickOutListener(e) {
    if (!includes(get(e, 'toElement.id'), 'helpIcon') && !includes(get(e, 'target.id'), 'helpIcon')
      && !includes(get(e, 'target.ownerSVGElement.id'), 'helpIcon'))
      this.setState({openPopper: {}})
  }

  renderAccountDetails(account) {
    const {classes, t} = this.props
    const country = get(this.props.viewer, 'address.country', '')
    const availableCurrencies = getAccountCurrencies(account, country)
    const accountTypeForStats = getAccountTypeForStats(account)
    if (!accountTypeForStats) return
    const label = this.getLabel(account)
    return <ClickAwayListener onClickAway={(e) => this.clickOutListener(e)}>
      <Grid container spacing={0} onClick={(e) => e.stopPropagation()}>
        <Fab className={classes.buttonPopper} onClick={() => this.setState({openPopper: {}})}>
          <CloseIcon/>
        </Fab>
        <Grid item xs={12}>
          <Typography variant='body2'>
            {label.join(' ')}
          </Typography>
        </Grid>
        {map(get(accountTypeStats[accountTypeForStats],'details'), (value, key) => {
          const country = toLower(get(this.props.viewer, 'address.country', ''))
          const company = get(this.props.viewer, 'company', '')
          const currValue = isObject(value)
            ? !isWhiteLabel() && get(value, company, '')
              ? get(value[company], country)
                ? value[company][country]
                : get(value[company], 'default', '')
              : get(value, 'default', '')
                ?  get(value, 'default', '')
                : value
            : value
          return <Grid container key={key}>
            <Grid item xs={6}>
              <Typography variant='body1'>{t(messages[key].i18nKey)}:</Typography>
            </Grid>
            <Grid item xs={6} className={classes.flexCenter}>
              <Typography variant='body1'>{isObject(currValue)
                ? t(messages[`${currValue['subtext']}`].i18nKey, {value: currValue['value']})
                : t(messages[`${key}Text`].i18nKey, {value: currValue, currencies: availableCurrencies})}
              </Typography>
            </Grid>
          </Grid>
        })}
        {!isNil(get(accountTypeStats[accountTypeForStats],'commission')) && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant='body2' className={classes.subtitlePopper}>
              {t(messages.commission.i18nKey)}:
            </Typography>
          </Grid>
          {map(get(accountTypeStats[accountTypeForStats],'commission'), (value, key) => {
            const country = get(this.props.viewer, 'address.country', '')
            const commissionValue = get(value, `${country}.value`) || value.value
            return <Grid container key={key}>
              <Grid item xs={6}>
                <Typography variant='body1'>{t(messages[key].i18nKey)}:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body1'>{t(messages[value.text].i18nKey, {value: commissionValue})}</Typography>
              </Grid>
            </Grid>
          })}
        </React.Fragment>}
        <Grid item xs={12}>
          <Typography variant='body2' className={classes.subtitlePopper}>
            {t(messages.benefits.i18nKey)}:
          </Typography>
        </Grid>
        {map(get(accountTypeStats[accountTypeForStats],'benefits'), (benefit) =>
          <Grid item xs={12} key={benefit}>
            <Typography variant='body1'>
              <CheckIcon className={classes.checkIcon} />
              {t(messages[benefit].i18nKey)}
            </Typography>
          </Grid>
        )}
        {get(accountTypeStats[account.accountSubtype], 'note') &&
          <Grid item xs={12}>
            <Typography variant='body1' className={classes.subtitlePopper}>
              {t(messages[get(accountTypeStats[account.accountSubtype], 'note')].i18nKey)}
            </Typography>
          </Grid>
        }
      </Grid>
    </ClickAwayListener>
  }

  showAccountdDetails(event, key, account) {
    const {currentTarget} = event
    event.stopPropagation()
    this.setState(prevState => ({
      openPopper: {
        [key]: !prevState.openPopper[key]
      },
      anchorEl: currentTarget,
    }))
  }

  getCurrenciesList() {
    const {viewer} = this.props
    const {accountType, accountSubtype} = this.state

    let currenciesList = []
    // Filter out from all the supported currencies the currencies that are disallowed per account type
    if (accountType && accountTypes[accountType].disallowedCurrencies) {
      currenciesList = filter(currencies, (p)=> !includes(accountTypes[accountType].disallowedCurrencies, p.key))
    }
    // Filter out from all the currencies that are allowed from the account type
    // the currencies that are disallowed per subtype
    if (accountSubtype && accountSubtypes[accountSubtype].disallowedCurrencies) {
      currenciesList = reject(currenciesList, (p) => includes(accountSubtypes[accountSubtype].disallowedCurrencies, p.key))
    }

    currenciesList = map(currenciesList, (o) => ({
      ...o,
      label: `${o.label} ( ${o.symbol} )`,
      icon: Images[`${o.key.toLowerCase()}.png`],
    }))

    if (accountType && accountTypes[accountType].additionalCountryCurrencies) {
      const country = find(countries, {key: get(viewer.address, 'country')})
      const subtype = find(accountSubtypes, {key: accountSubtype}) || {}
      const countryCurrencies = get(
        find(
          accountTypes[accountType].additionalCountryCurrencies,
          (a) => a.country === country.value && a.accountSubtype === subtype.value
        ),
        'currencies'
      )
      currenciesList.push(...map(filter(currencies, (c) => includes(countryCurrencies, c.value)), (c) => ({
        ...c,
        label: `${c.label} ( ${c.symbol} )`,
        icon: Images[`${c.key.toLowerCase()}.png`],
      })))
    }

    if (accountSubtype && accountSubtypes[accountSubtype].additionalCountryCurrencies) {
      const country = find(countries, {key: get(viewer.address, 'country')})
      const countryCurrencies = get(
        find(accountSubtypes[accountSubtype].additionalCountryCurrencies, (a) => a.country === country.value),
        'currencies'
      )
      currenciesList.push(...map(filter(currencies, (c) => includes(countryCurrencies, c.value)), (c) => ({
        ...c,
        label: `${c.label} ( ${c.symbol} )`,
        icon: Images[`${c.key.toLowerCase()}.png`],
      })))
    }

    if (accountType && accountTypes[accountType].disallowedCountryCurrencies) {
      const country = find(countries, {key: get(viewer.address, 'country')})
      const dissallowedCurrencies = get(
        find(accountTypes[accountType].disallowedCountryCurrencies, (a) => a.country === country.value),
        'currencies'
      )
      currenciesList = filter(currenciesList, (c) => !includes(dissallowedCurrencies, c.value))
    }

    return currenciesList
  }

  getAccountsCategoryList(accountTypesList) {
    const {accounts} = this.context
    const {accountSubtype, accountType: typeKey} =  this.state
    if (!accounts) return []
    const allowedAccountsList = []
    const type = find(accountTypes, {key: typeKey})
    const selectedAccountType = find(accountTypesList, (l) => l.accountSubtype === accountSubtype && l.isDemo === type.isDemo)
    selectedAccountType && map(selectedAccountType.accountTypesList, (type) => {
      const accountType = get(accountTypes,type)
      if (accountType) {
        const account = {
          label:`${accountType.label}`,
          icon: accountType.shortLabel,
          accountType: accountType.key,
          accountSubtype: accountSubtype,
          category: accountType.category,
          subCategory: accountType.subCategory || accountSubtype || '',
          baseProduct: accountType.baseProduct && accountType.baseProduct.replace(/^\w/, c => c.toLowerCase()),
          walletProduct: accountType.walletProduct,
          isDemo: accountType.isDemo,
          '__typename': type,
          supportsDynamicLeverage: accountType.supportsDynamicLeverage,
        }
        allowedAccountsList.push(account)
      }
    })
    return sortBy(allowedAccountsList, (a) => !!a.walletProduct)
  }

  renderPartnerMessageNotification() {
    const {t, history} = this.props
    const {showPartnerMessage} = this.state
    return <UiNotification
      title={'additionalDocumentsNeeded'}
      open={showPartnerMessage}
      status={'success'}
      type={'document-upload'}
      buttonMessage={t(messages.uploadDocuments.i18nKey)}
      onClose={() => history.push('/accounts?accountCreated=true')}
      buttonAction={() => history.push('/settings/profile/documents')}
      linkActionMessage={t(messages.maybeLater.i18nKey, messages.maybeLater.defaults)}
      linkAction={'/accounts?accountCreated=true'}
    >
      <Trans {...messages.messagePartnerAddAccount} />
    </UiNotification>
  }

  compareAccountLink() {
    const {viewer} = this.props
    logEventCustomParams('compareAccountTypes', {userId: get(viewer, 'id')})
    return this.props.history.push({pathname: '/compare-accounts', state: {country: get(viewer, 'address.country')}})
  }

  getLabel(account) {
    const {locale} = this.context
    const {t} = this.props
    const firstPart = `${account.showDemoLabel ? `${toUpper(t(messages.demo.i18nKey))} ` : ''}`
    const secondPart =  account.label
    const hasRevese = get(localesReverseTextAccountTypes, locale)
    return (hasRevese && includes(hasRevese, get(account, 'accountSubtype'))) ? reverse(filter(split(`${firstPart} ${secondPart}`, ' '))) : [firstPart, secondPart]
  }

  showLeverageNotification() {
    const {classes} = this.props
    const {accountSubtype, accountType} = this.state
    const {featuresConfig:{copyTradingEnabled}}= config
    const showDifferentTitle = includes(copyTradingEnabled?.accountTypesCategory, accountTypes[accountType]?.category)
      && includes(copyTradingEnabled?.accountSubTypes, accountSubtype)

    return <Grid item xs={12} lg={6} className={!isMobile() ? classes.leverageTypeNotification : ''}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <NotificationBar status="info"
          title={<React.Fragment>
            {map(leverageTypeTooltip , (a, index)=> <div key={index} className={classes.leverageTooltip}>
              <strong className={classes.bold}>
                <Trans {...messages[a.title(showDifferentTitle)?.i18nKey]} />
              </strong>
              <Typography variant={'caption'}>
                <Trans {...messages[a.description?.i18nKey]} />
              </Typography>
            </div>
            )}
          </React.Fragment>}
        />
      </Grid>
    </Grid>
  }

  render() {
    const {themePreference, accounts, locale, clientType} = this.context
    const {classes, t, history, viewer} = this.props

    const {step, accountType, openPopper, anchorEl, companyObject, currency, accountSubtype} = this.state
    const {accountTypesComparisonUrl, key, featuresConfig:{copyTradingEnabled}} = config
    const checkSubscription = accountTypes[accountType] && accountTypes[accountType]['subscription']
    const moreDetails = get(accountTypes[accountType], 'walletProduct', '')
    const company = get(this.props.viewer, 'company', '')
    const country = toLower(get(this.props.viewer, 'address.country', ''))
    const allowedAccountTypes = get(viewer, 'allowedAccountTypes') || []
    let allowedAccounts = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes, viewer)
    const showComparison = accountTypesComparisonUrl && (isEmpty(allowedAccountTypes) || allowedAccountTypes.length > 1)
      && !(isWhiteLabel() && has(whiteLabels[key], 'supportedSubtypes'))
    const missingDocs = values(getMissingDocs(viewer)).some(d => d === true)
    const disableAddNewAccount = clientTypes[clientType]?.disableAddNewAccount || viewer?.isContractor
    const productsToExclude = [
      'forexCopyTradingAccountMT5Vanuatu',
      'forexCopyTradingAccountMT5Comoros'
    ]
    const softLaunchSupportedCountries = ['BQ','TC','CC','NU','SZ','TR']
    if (last(get(viewer, 'email').split('@')) !== 'bdswiss.com' || !includes(softLaunchSupportedCountries, upperCase(country))) {
      allowedAccounts = reject(allowedAccounts, (a)=> includes(productsToExclude, a?.baseProduct))
    }
    if (disableAddNewAccount) {
      return <Redirect to={'/accounts'}/>
    }

    if (allowedAccounts.length < 1) return <UiNotification
      title={'limitReached'}
      open
      status={'failure'}
      type="limit"
      buttonMessage={t(messages.contactUs.i18nKey)}
      onClose={() => history.push('/')}
      buttonAction={() => history.push('/support')}
    >
      <Trans {...messages.maximumAllowedAccounts} />
    </UiNotification>

    let accountCategoryList, categoryList
    if (step>0) {
      accountCategoryList = this.getAccountsCategoryList(allowedAccounts)
      categoryList = map(accountCategoryList, (account, key) => {
        const label = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
        const accountType = accountTypes[account.__typename]
        const accountSubtype = getAccountSubtype(account.accountSubtype)
        const shortLabel = (accountType.subCategory && accountType.shortLabel) || (account.accountSubtype
          && get(accountSubtype, 'shortLabel')) || accountType.shortLabel || account.currency
        return <Card classes={{root: classes.cardRoot}} className={classes.pointer} key={key}
          onClick={()=> this.chooseAccount(account.accountType,account.accountSubtype)}>
          <Grid container>
            <Grid item xs={2} className={classes.badgeDiv}>
              <div className={classNames(classes.typeBadge,classes[account.subCategory] || classes[account.accountSubtype]
                || classes[account.category])}>{shortLabel}</div>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='subtitle1' className={classes.label}>{label}</Typography>
              <Typography variant='caption'>
                { get(messages, `${account.baseProduct}Desc`)
                  ? t(messages[`${account.baseProduct}Desc`].i18nKey, messages[`${account.baseProduct}Desc`].defaults)
                  : (get(messages, `${account.category}${account.subCategory}CategoryDesc`)
                    ? t(messages[`${account.category}${account.subCategory}CategoryDesc`].i18nKey, messages[`${account.category}${account.subCategory}CategoryDesc`].defaults)
                    : (get(messages, `${account.category}${account.subCategory}Desc`))
                      ? t(messages[`${account.category}${account.subCategory}Desc`].i18nKey, messages[`${account.category}${account.subCategory}Desc`].defaults)
                      : '')
                }
              </Typography>
              {get(accountType, 'supportsDynamicLeverage') && (accountSubtype.key === 'basic') && !isMenaCountry(upperCase(get(viewer, 'address.country')))
               && includes(get(copyTradingEnabled, 'accountTypesCategory'), account.category) && <Typography variant='caption' ><Trans {...messages.copyTradingNotAvailableonDynamic}/></Typography>}
            </Grid>
          </Grid>
        </Card>
      })

    }

    const sortedAccounts = sortBy(flatten(map(groupBy(allowedAccounts, 'accountSubtype'))), a => !!a.needDeposit)
    const accountList = map(sortedAccounts, (account, key) => {
      const averageSpreadConfig = get(accountTypeStats[account.accountSubtype], 'details.averageSpread')
      const averageSpreadValue = isObject(averageSpreadConfig)
        ? !isWhiteLabel() && get(averageSpreadConfig, company, '')
          ? get(averageSpreadConfig[company], country)
            ? averageSpreadConfig[company][country]
            : get(averageSpreadConfig[company], 'default', '')
          : get(averageSpreadConfig, 'default', '')
            ?  get(averageSpreadConfig, 'default', '')
            : averageSpreadConfig
        : averageSpreadConfig
      const label = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
      return <Card classes={{root: classes.cardRoot}}
        className={account.needDeposit ? classes.disabledCard : classes.pointer} key={key}
        onClick={() => !account.needDeposit && this.chooseAccount(account.accountType, account.accountSubtype)}>
        <Grid container>
          <Grid item xs={2} className={classes.badgeDiv}>
            <div className={classNames(classes.typeBadge,classes[account.subCategory] || classes[account.accountSubtype]
      || classes[account.category])}>{account.icon}</div>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1" className={classes.label}>
              {label}
              {!account.isDemo && get(accountTypeStats, getAccountTypeForStats(account)) &&
              <Help id={'helpIcon'} className={classes.helpIcon} onClick={(e) => this.showAccountdDetails(e, key)} />}
              {account.available && account.maxAllowed > 1 && <Typography variant="caption" className={classes.availableAccounts}>
                <Trans {...messages.availableAccounts} values={{available: account.available, maxAllowed: account.maxAllowed}} />
              </Typography>}
            </Typography>
            {account.needDeposit
              ? <Typography variant="caption">
                <Link to={`/transactions/${account.needDeposit.id}/deposit`} className={classNames(classes.link)}> <Trans {...messages.deposit} /> </Link>
                <Trans {...messages.needDeposit} values={{accountType: account.label}} />
              </Typography>
              : <Typography variant="caption">
                {(account.isDemo) && get(messages, `${account.category}${account.accountSubtype}DemoDesc`) ? t(messages[`${account.category}${account.accountSubtype}DemoDesc`].i18nKey)
                  : get(messages, `${account.category}${account.accountSubtype}Desc`)
                    ? t(messages[`${account.category}${account.accountSubtype}Desc`].i18nKey, {averageSpread: averageSpreadValue})
                    : get(messages, `${account.baseProduct}Desc`)
                      ? t(messages[`${account.baseProduct}Desc`].i18nKey, messages[`${account.baseProduct}Desc`].defaults)
                      : t(messages[`${account.category}${account.subCategory}Desc`].i18nKey, messages[`${account.category}${account.subCategory}Desc`].defaults)
                }
              </Typography>}
          </Grid>
          <Popper id={key} open={openPopper[key] || false} anchorEl={anchorEl} className={classes.popper}
            placement={isMobile() ? 'bottom' : 'right-end'}>
            <span>{this.renderAccountDetails(account)}</span>
          </Popper>
        </Grid>
      </Card>}
    )

    const allowedCurrencyList = this.getCurrenciesList()
    const currencyList = allowedCurrencyList.map( (currency) =>
      <Card classes={{root: classes.cardRoot}} key={currency.key} onClick={()=> this.chooseCurrency(currency.key)}>
        <Grid container>
          <Avatar alt={currency['icon']} src={currency['icon']} className={classes.currIcon}/>
          <Grid item>
            <Typography variant='subtitle1' className={classes.label}>{currency['label']}</Typography>
          </Grid>
        </Grid>
      </Card>
    )

    const subtype = find(accountSubtypes, {key: this.state.accountSubtype}) || {}
    const categoryStep = (step === 1 && accountCategoryList?.length > 1) || (step === 1 && checkSubscription)
    const currenciesStep = (step === 1 && accountCategoryList?.length <= 1 && !checkSubscription && !isCentAccount({__typename: accountType}) && !get(subtype, 'skipCurrencyStep')) ||
      (step === 2 && (accountCategoryList?.length > 1 || checkSubscription) && !isCentAccount({__typename: accountType}) && !get(subtype, 'skipCurrencyStep'))
    const getAvailableCurrenciesDynamicLeverage = map(filter(currencies, (a) => a.allowDynamicLeverage), 'value')
    const accountSubTypesDynamicLeverage = map(filter(accountSubtypes, (a) => {
      const upperCaseCountry = upperCase(get(viewer, 'address.country'))
      const isDynamic = this.state.leverageType || get(accountTypes[accountType], 'supportsDynamicLeverage')
      const specialGroup = a.specialGroup && a.specialGroup(upperCaseCountry, isDynamic)

      return specialGroup?.[company]?.isDynamic
    }), 'key')

    const checkLeverageTypeStep = accountCategoryList?.length > 1 ? step === 3 : step === 2
    const showLeverageTypeStep = !isMenaCountry(upperCase(get(viewer, 'address.country')))
      && includes(getAvailableCurrenciesDynamicLeverage, currency)
        && includes(accountSubTypesDynamicLeverage, accountSubtype)
          && checkLeverageTypeStep
          && get(accountTypes[accountType], 'supportsDynamicLeverage')
    const passwordStep =  (
      (step === 4 && (accountCategoryList?.length > 1 || checkSubscription)) ||
      (step === 3 && (accountCategoryList?.length > 1 || checkSubscription || (accountCategoryList?.length <= 1 && !isEmpty(this.state.leverageType)))) ||
      (step === 2 && !checkSubscription && ((accountCategoryList?.length <= 1 && !isCentAccount({__typename: accountType})) ||
        (accountCategoryList?.length > 1 && isCentAccount({__typename: accountType})))) ||
      (step === 1 && accountCategoryList?.length <= 1 && (isCentAccount({__typename: accountType}) || get(subtype, 'skipCurrencyStep')))
    ) && !moreDetails && !showLeverageTypeStep

    const detailsStep =  ((step === 3 && accountCategoryList?.length > 1) ||
      (step === 2 && accountCategoryList?.length <= 1  && !checkSubscription && !isCentAccount({__typename: accountType}))) && moreDetails

    const checkLeverageMaxStep = (step === 2 && accountCategoryList?.length <= 1) ? 4 : 5
    const maxStep = showLeverageTypeStep || step === 4  ? checkLeverageMaxStep :
      accountCategoryList?.length <= 1 && isCentAccount({__typename: accountType}) ? 2
        : (accountCategoryList?.length > 1 && !isCentAccount({__typename: accountType}))
          || (step === 3 && accountCategoryList?.length <= 1) ? 4 : 3

    return <FullScreenDialog
      fullScreen
      desktopOnly
      open={true}
    >
      <PageTitle
        themePreference={themePreference}
        onBack={() => this.decreaseStep()}
        title={t(messages.addNewAccount.i18nKey, messages.addNewAccount.defaults)}
        withoutPadding={isMobile()}
      />
      <PageBody>
        <Grid container>
          <Grid item xs={12} sm={(categoryStep && checkSubscription) ? false : 8} lg={(categoryStep && checkSubscription) ? false : 6}>
            {step > 0 && <span><MobileStepper
              variant="progress"
              steps={maxStep+1}
              position="static"
              activeStep={step+1}
              className={classes.stepper}
              classes={{progress: classes.stepper}}
            /><Typography variant='caption'>{step+1}/{maxStep}</Typography></span>}
            {step === 0 && <div>
              <PageSubTitle>
                <Trans {...messages.selectAccountType} />
                {showComparison && <Button color="primary" className={classes.buttonCompare} classes={{label: classes.labelButton}}
                  onClick={() => this.compareAccountLink()}>
                  <Trans {...messages.compareAccountTypes} />
                </Button>}
              </PageSubTitle>{ accountList }</div>}
            {categoryStep && !checkSubscription && <div>
              <PageSubTitle><Trans {...messages.selectAccountType} /></PageSubTitle>{ categoryList }</div>}
            {categoryStep && checkSubscription && <div>
              <PageSubTitle><Trans {...messages.selectPlan} /></PageSubTitle>
              <ChangePlan onCardClick={(plan)=> this.chooseAccount(accountType,get(plan, 'accountSubtype'))}/></div>}
            {currenciesStep && <div>
              <PageSubTitle>
                <Trans {...messages.selectCurrency} />
                {checkSubscription && <Typography variant='caption'> <Trans {...messages.tradingAccountCurrency} /></Typography>}
              </PageSubTitle>
              {currencyList}
            </div>}
            {showLeverageTypeStep && <div>
              <PageSubTitle>
                <Trans {...messages.selectLeverageType} />
              </PageSubTitle>
              {this.renderLeverageType()}
            </div>}
            {passwordStep && this.renderForm()}
            {detailsStep && this.renderDetailsForm()}
            {(missingDocs && detailsStep) && this.renderPartnerMessageNotification()}
          </Grid>
          {showLeverageTypeStep && this.showLeverageNotification()}
        </Grid>
        {accountType && accountTypes[accountType].kycRequired && get(viewer, 'kycStatus') !== kycStatuses.approved.value && <AlertDialog
          open
          onClose={() => this.setState({accountType: '', step: 0})}
          agreeText={t(messages[missingDocs ? 'verifyAccount' : 'close'].i18nKey, messages[missingDocs ? 'verifyAccount' : 'close'].defaults)}
          onAgree={() => missingDocs ? history.push('/settings/profile') : this.setState({accountType: '', step: 0})}
          disagreeText={missingDocs ? t(messages.cancel.i18nKey, messages.cancel.defaults) : ''}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages[missingDocs ? 'accountKycRequiredMsg' : 'accountKycRequiredPendingMsg']} values={{account: accountTypes[accountType].label}}/></Typography>
            </Grid>
          </Grid>
        </AlertDialog>}
      </PageBody>
    </FullScreenDialog>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        viewer,
      }
    }
  }),
  graphql(ADD_ACCOUNT_MUTATION, {
    name: 'addAccountMutation',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
)(AddAccount)
