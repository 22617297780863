import React from 'react'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import {flowRight as compose, map, get, find, orderBy, filter, capitalize, reject} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {accountTypes} from '@bdswiss/common-enums'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import config from '../../../config'
import Amount from '../../Common/Amount'
import messages from '../../../assets/messages'
import {AlertDialog} from '../../Common/Dialog'
import AppContext from '../../Common/contexts/AppContext'
import {getAccountSubtype, getAccountLabel, isCopyTradingAccountFollower} from '../../../common/utils/accounts'
import {getCurrentTheme, isDarkTheme, isMobile} from '../../../common/utils'
import {SvgIcon} from '@mui/material'
import {ReactComponent as CloseIcon} from '../../../assets/images/closeIcon.svg'

const styles = theme => ({
  checkboxLabel: {
    padding: '10px 0',
  },
  alertBodyClass: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(300)]: {
      whiteSpace: 'unset',
      minWidth: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 600
    }
  },
  dialogPaper: {
    borderRadius: '8px',
  },
  dialogPaperDark: {
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[900],
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'stretch',
  },
  dialogTitle: {
    textAlign: 'left',
    padding: '24px 24px 16px 24px',
  },
  disagreeButton: {
    color: theme.palette.grey[600],
  },
  disagreeButtonDark: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: theme.palette.grey[400],
  },
  closeIcon: {
    backgroundColor: 'transparent',
    verticalAlign: 'middle',
    color: theme.palette.grey[400],
    [theme.direction === 'rtl' ? 'left' : 'right']: '15px',
  },
  titleContainer: {
    '&&:first-child': {
      marginRight: '35px',
    },
    flexWrap: 'nowrap',
  },
  agreeButton: {
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.extralightgrey.color,
    },
  },
  agreeButtonDark: {
    '&.Mui-disabled': {
      color: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[900],
      border: `1px solid ${theme.palette.grey[800]}`,
    },
  },
  dialogActions: {
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
  },
  buttonContainers: {
    '&.MuiGrid-item': {
      padding: '4px 0px 4px 0px',
    },
  },
  accountLabel: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
})

class ForexWebtraderModal extends React.Component {
  static contextType = AppContext
  state = {
    selectedAccount: ''
  }

  getLabel(account) {
    const {classes, t} = this.props
    const {downloadTrader, hideMt4Mentions} = config
    const {locale} = this.context
    const accountType = find(accountTypes, {key: get(account, '__typename')})
    const downloadTraderInfo = get(downloadTrader, get(accountType, 'category'))
    const label = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
    const isMobileDevice = isMobile()

    return <React.Fragment>
      <Typography variant={isMobileDevice ? 'inherit' : 'subtitle1'} className={isMobileDevice ? classes.accountLabel : ''}>
        {`${label} ${hideMt4Mentions ? '' : get(downloadTraderInfo, 'shortLabel', '')} - ${account.remoteId}`}
      </Typography>
      <Typography variant="subtitle1" >
        <Amount
          value={account.balance} currency={account.currency} locale='en-US' classes={{
            root: classes.amount,
            currency: classes.amountCurrency,
            value: classes.amountValue,
            fraction: classes.amountFraction
          }} />
      </Typography>
    </React.Fragment>
  }

  getCustomTitle(includeCloseIcon) {
    const {t, classes, onClose} = this.props
    const translatedTitle = t(messages.selectTradingAccount.i18nKey, messages.selectTradingAccount.defaults)
    const title = isMobile() ? capitalize(translatedTitle) : translatedTitle
    return includeCloseIcon ? (
      <Grid container justifyContent='space-between' className={classes.titleContainer}>
        <Grid item>{title}</Grid>
        <Grid item>
          <SvgIcon
            viewBox='-6 -3 24 24'
            className={classes.closeIcon}
            onClick={() => onClose()}
            component={CloseIcon}
          />
        </Grid>
      </Grid>) : title
  }

  render() {
    const {selectedAccount} = this.state
    const {t, classes, showAccountsModal, tradingAccounts, onClose} = this.props
    let {onAgree} = this.props
    const excludeAccounts = reject(tradingAccounts, (a)=> isCopyTradingAccountFollower(a))
    const liveAccounts = orderBy(filter(excludeAccounts, (account) => !account.isDemo), ['balance'], ['desc'])
    const demoAccounts = orderBy(filter(tradingAccounts, (account) => account.isDemo), ['balance'], ['desc'])
    const login = selectedAccount || get(liveAccounts, '[0].login') || get(demoAccounts, '[0].login')

    if (!onAgree) {
      onAgree = (login) => {
        onClose()
        window.open(`/trade/forex/${login}`, '_blank')
      }
    }

    const orderedAccounts = [...liveAccounts, ...demoAccounts]
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    const isMobileDevice = isMobile()
    return <AlertDialog
      open={showAccountsModal}
      onClose={() => this.props.onClose()}
      title={this.getCustomTitle(isMobileDevice)}
      agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
      buttonDisabled={!selectedAccount || !showAccountsModal}
      hideProgressBar={showAccountsModal}
      disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
      onAgree={() => onAgree(login)}
      alertBodyClass={classes.alertBodyClass}
      backdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.7)'}}}
      customClasses={isMobileDevice ? {
        classes: {paper: isDark ? classes.dialogPaperDark : classes.dialogPaper},
        disagreeButton: isDark ? classes.disagreeButtonDark : classes.disagreeButton,
        agreeButton: isDark ? classes.agreeButtonDark : classes.agreeButton,
        dialogTitle: classes.dialogTitle,
        buttonGroup: classes.buttonGroup,
        dialogActions: classes.dialogActions,
        agreeButtonContainer: classes.buttonContainers,
        disagreeButtonContainer: classes.buttonContainers,
      }: {}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            name="account"
            value={selectedAccount}
            onChange={(event) => this.setState({selectedAccount: event.target.value})}
          >
            {map(orderedAccounts, (account) =>
              <FormControlLabel
                classes={{label: classes.checkboxLabel}}
                key={account.login} value={account.login}
                control={<Radio color="primary" />}
                label={this.getLabel(account)} />
            )}
          </RadioGroup>
        </Grid>
      </Grid>
    </AlertDialog>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(ForexWebtraderModal)
